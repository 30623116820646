import React, { useState, useEffect } from 'react';







import axios from 'axios';







import './PRReadinessTool.css';







import CalendlyWidget from './CalendlyWidget';







import { useNavigate } from 'react-router-dom';







import { Analytics } from "@vercel/analytics/react";















// Add this line after the import







axios.defaults.headers.common = {};















const API_BASE_URL = 'https://server.broadviewassessment.com';















const ProcessingVisual = () => (







  <div className="processing-visual">







    <div className="spinner"></div>







    <p>Processing your results...</p>







  </div>







);















const PRReadinessTool = () => {







  const [currentStep, setCurrentStep] = useState(0);







  const [formData, setFormData] = useState({







    fullName: '',







    email: '',







    phoneNumber: '',







    organizationName: '',







    website: '',







    projectOverview: '',







    goals: '',







    budget: '',







    servicesNeeded: [],







    startDate: '',







    milestones: '',







    additionalInfo: '',







    referralSource: '',







    socialMedia: {







      facebook: { use: false, followers: '', url: '' },







      instagram: { use: false, followers: '', url: '' },







      twitter: { use: false, followers: '', url: '' },







      linkedin: { use: false, followers: '', url: '' },







      youtube: { use: false, followers: '', url: '' },







      tiktok: { use: false, followers: '', url: '' },







    },







    previousPR: false,







    lastPressRelease: '',







    lastMediaCoverage: '',







    recentCoverage: '',







  });















  const [errors, setErrors] = useState({});







  const [isSubmitted, setIsSubmitted] = useState(false);







  const [score, setScore] = useState(null);







  const [showCalendly, setShowCalendly] = useState(false);







  const navigate = useNavigate();















  const [aiRecommendation, setAiRecommendation] = useState('');







  const [isGeneratingRecommendation, setIsGeneratingRecommendation] = useState(false);







  const [error, setError] = useState(null);







  const [isProcessing, setIsProcessing] = useState(false);







  const [isLoading, setIsLoading] = useState(false);  // New state for loading







  // Add a new loading state for the first page







  const [isFirstStepSubmitting, setIsFirstStepSubmitting] = useState(false);







  // Add this new component for the loading state







  const FirstStepLoader = () => (







    <div className="first-step-loader">







      <div className="spinner"></div>







      <p>Saving your information...</p>







    </div>







  );















  // Add this useEffect for debugging







  useEffect(() => {







    console.log('State updated - isSubmitted:', isSubmitted);







  }, [isSubmitted]);















  const handleInputChange = (e) => {







    const { name, value } = e.target;







    setFormData({ ...formData, [name]: value });







    







    // Save user details to localStorage when they're entered







    if (['fullName', 'email', 'organizationName'].includes(name)) {







      localStorage.setItem(name, value);







    }







  };















  const handleCheckboxChange = (e) => {







    const { checked, value } = e.target;







    if (checked) {







      setFormData({ ...formData, servicesNeeded: [...formData.servicesNeeded, value] });







    } else {







      setFormData({







        ...formData,







        servicesNeeded: formData.servicesNeeded.filter(service => service !== value)







      });







    }







  };















  const handleSubmit = async (e) => {







    e.preventDefault();







    setIsProcessing(true);







    setError(null);







    setIsLoading(true);















    try {







      const calculatedScore = calculateScore();







      console.log('Submitting answers:', { email: formData.email, answers: formData, score: calculatedScore });







      const response = await axios.post(`${API_BASE_URL}/api/responses/submit-answers`, {







        email: formData.email,







        answers: formData,







        score: calculatedScore







      });















      console.log('Submit response:', response.data);















      setScore(calculatedScore);







      setAiRecommendation(response.data.data.aiRecommendation);







      setIsSubmitted(true);







    } catch (error) {







      console.error('Error:', error);







      let errorMessage = 'An error occurred while submitting your answers and generating recommendations. Please try again.';







      if (error.response) {







        console.error('Error response:', error.response.data);







        errorMessage = error.response.data.message || errorMessage;







        if (error.response.data.details) {







          console.error('Error details:', error.response.data.details);







        }







        if (error.response.data.stack) {







          console.error('Error stack:', error.response.data.stack);







        }







      }







      setError(errorMessage);







    } finally {







      setIsProcessing(false);







      setIsLoading(false);







    }







  };















  const handleSocialMediaChange = (platform, field, value) => {







    setFormData(prevData => ({







      ...prevData,







      socialMedia: {







        ...prevData.socialMedia,







        [platform]: {







          ...prevData.socialMedia[platform],







          [field]: value







        }







      }







    }));







  };















  const questions = [







    {







      title: "Personal Information",







      fields: [







        { name: "fullName", label: "Full Name", type: "text" },







        { name: "email", label: "Email Address", type: "email" },







        { name: "phoneNumber", label: "Phone Number", type: "tel" },







      ]







    },







    {







      title: "Organization Details",







      fields: [







        { name: "organizationName", label: "Organization Name", type: "text" },







        { name: "website", label: "Website", type: "url", options: ["I do not have a website"] },







      ]







    },







    {







      title: "Project Overview",







      fields: [







        { 







          name: "projectOverview", 







          label: "What are you looking to promote?", 







          type: "textarea",







          minLength: 100,







          infoText: "Minimum of 100 characters required"







        },







      ]







    },







    {







      title: "Goals and Objectives",







      fields: [







        { 







          name: "goals", 







          label: "What are your main goals for this PR campaign?", 







          type: "textarea",







          minLength: 100,







          infoText: "Minimum of 100 characters required"







        },







      ]







    },







    {







      title: "Budget",







      fields: [







        {







          name: "budget",







          label: "What is your estimated budget range for this project?",







          type: "select",







          options: [







            "$1,000 and under",







            "$1,001 - $3,000",







            "$3,001 - $5,000",







            "$5,001 - $10,000",







            "$10,001 - $20,000",







            "$20,000+",







            "Not sure ($1,000+ but I would like guidance)",







          ]







        },







      ]







    },







    {







      title: "Services Needed",







      fields: [







        { name: "servicesNeeded", label: "Public Relations", type: "checkbox" },







        { name: "servicesNeeded", label: "Web Design", type: "checkbox" },







        { name: "servicesNeeded", label: "Social Media Management", type: "checkbox" },







        { name: "servicesNeeded", label: "Other", type: "checkbox" },







        { 







          name: "otherServices", 







          label: "Please specify other services", 







          type: "text", 







          condition: () => formData.servicesNeeded.includes("Other")







        },







      ]







    },







    {







      title: "Timeline",







      fields: [







        { name: "startDate", label: "Proposed Start Date", type: "date" },







        { name: "milestones", label: "Are there any specific milestone dates or deadlines important to your campaign?", type: "textarea" },







      ]







    },







    {







      title: "Additional Information",







      fields: [







        { name: "additionalInfo", label: "Is there anything else you'd like us to know about your project or organization?", type: "textarea" },







        { name: "referralSource", label: "How did you hear about us?", type: "text" },







      ]







    },







    {







      title: "Social Media Presence",







      fields: [







        { name: "socialMedia", label: "Which social media platforms does your organization currently use?", type: "socialMedia" },







      ]







    },







    {







      title: "Previous PR Experience",







      fields: [







        { 







          name: "previousPR", 







          label: "Has your organization engaged in public relations activities before?", 







          type: "radio", 







          options: ["Yes", "No"],







          vertical: true // Add this to make options appear vertically







        },







        { name: "lastPressRelease", label: "If yes, when was the last time you issued a press release?", type: "select", options: ["Within the last month", "1-3 months ago", "3-6 months ago", "6-12 months ago", "Over a year ago", "Never issued a press release"], condition: formData.previousPR === "Yes" },







        { name: "lastMediaCoverage", label: "When was the last time your organization received media coverage?", type: "select", options: ["Within the last month", "1-3 months ago", "3-6 months ago", "6-12 months ago", "Over a year ago", "Never received media coverage", "Not sure"] },







        { name: "recentCoverage", label: "If you've received media coverage, please provide a brief description or links to recent coverage:", type: "textarea", condition: formData.lastMediaCoverage !== "Never received media coverage" && formData.lastMediaCoverage !== "Not sure" },







      ]







    },







  ];















  const renderQuestion = (question) => {







    return (







      <div key={question.title} className="question-container">







        <h2>{question.title}</h2>







        {question.fields.map((field) => {







          if (field.condition !== undefined && !field.condition) {







            return null;







          }







          return renderField(field);







        })}







      </div>







    );







  };















  const renderField = (field) => {







    switch (field.type) {







      case 'text':







      case 'email':







      case 'tel':







      case 'url':







      case 'date':







        return (







          <div key={field.name} className="form-field">







            <label htmlFor={field.name}>{field.label}</label>







            <input







              type={field.type}







              id={field.name}







              name={field.name}







              value={formData[field.name]}







              onChange={handleInputChange}







              required={field.name === "fullName" || 

                       field.name === "email" || 

                       field.name === "phoneNumber" ||

                       field.name === "projectOverview" || 

                       field.name === "goals"}







            />







            {errors[field.name] && <span className="error">{errors[field.name]}</span>}







          </div>







        );







      case 'textarea':







        return (







          <div key={field.name} className="form-field">







            <label htmlFor={field.name}>{field.label}</label>







            <textarea







              id={field.name}







              name={field.name}







              value={formData[field.name]}







              onChange={handleInputChange}







              required={field.name === "projectOverview" || field.name === "goals"}







              minLength={field.minLength}







            />







            {field.infoText && <span className="info-text">{field.infoText}</span>}







            {errors[field.name] && <span className="error">{errors[field.name]}</span>}







          </div>







        );







      case 'select':







        return (







          <div key={field.name} className="form-field">







            <label htmlFor={field.name}>{field.label}</label>







            <select







              id={field.name}







              name={field.name}







              value={formData[field.name]}







              onChange={handleInputChange}







              required={false}







            >







              <option value="">Select an option</option>







              {field.options.map(option => (







                <option key={option} value={option}>{option}</option>







              ))}







            </select>







            {errors[field.name] && <span className="error">{errors[field.name]}</span>}







          </div>







        );







      case 'checkbox':







        return (







          <div key={`${field.name}-${field.label}`} className="form-field checkbox-field">







            <label>







              <input







                type="checkbox"







                id={`${field.name}-${field.label}`}







                name={field.name}







                value={field.label}







                checked={formData[field.name]?.includes(field.label) || false}







                onChange={handleCheckboxChange}







              />







              {field.label}







            </label>







          </div>







        );







      case 'radio':







        return (







          <div key={field.name} className="form-field radio-field">







            <p>{field.label}</p>







            <div className={field.vertical ? "radio-options-vertical" : "radio-options-horizontal"}>







              {field.options.map(option => (







                <div key={option}>







                  <input







                    type="radio"







                    id={`${field.name}-${option}`}







                    name={field.name}







                    value={option}







                    checked={formData[field.name] === option}







                    onChange={handleInputChange}







                    required







                  />







                  <label htmlFor={`${field.name}-${option}`}>{option}</label>







                </div>







              ))}







            </div>







            {errors[field.name] && <span className="error">{errors[field.name]}</span>}







          </div>







        );







      case 'socialMedia':







        return (







          <div key={field.name} className="form-field social-media-field">







            <p>{field.label}</p>







            {Object.keys(formData.socialMedia).map(platform => (







              <div key={platform} className="social-media-platform">







                <input







                  type="checkbox"







                  id={`use-${platform}`}







                  checked={formData.socialMedia[platform].use}







                  onChange={(e) => handleSocialMediaChange(platform, 'use', e.target.checked)}







                />







                <label htmlFor={`use-${platform}`}>{platform.charAt(0).toUpperCase() + platform.slice(1)}</label>







                {formData.socialMedia[platform].use && (







                  <>







                    <input







                      type="number"







                      placeholder="Followers"







                      value={formData.socialMedia[platform].followers}







                      onChange={(e) => handleSocialMediaChange(platform, 'followers', e.target.value)}







                    />







                    <input







                      type="url"







                      placeholder="Profile URL"







                      value={formData.socialMedia[platform].url}







                      onChange={(e) => handleSocialMediaChange(platform, 'url', e.target.value)}







                    />







                  </>







                )}







              </div>







            ))}







          </div>







        );







      default:







        return null;







    }







  };















  const validateStep = () => {







    const currentQuestions = questions[currentStep].fields;







    const newErrors = {};















    currentQuestions.forEach(field => {







      if (field.condition !== undefined && !field.condition) {







        return;







      }















      if (currentStep === 0) {







        const value = formData[field.name];







        if (!value) {







          newErrors[field.name] = 'This field is required';







        }







      } else if (field.name === "projectOverview" || field.name === "goals") {







        const value = formData[field.name];







        if (!value) {







          newErrors[field.name] = 'This field is required';







        } else if (field.minLength && value.length < field.minLength) {







          newErrors[field.name] = `Minimum ${field.minLength} characters required`;







        }







      }







    });















    setErrors(newErrors);







    return Object.keys(newErrors).length === 0;







  };















  const handleNext = async () => {







    if (validateStep()) {







      if (currentStep === 0) {







        setIsFirstStepSubmitting(true); // Show loader







        try {







          const response = await axios.post(`${API_BASE_URL}/api/responses/user-details`, {







            name: formData.fullName,







            email: formData.email,







            phoneNumber: formData.phoneNumber,







            organizationName: formData.organizationName







          });







          console.log('User details submitted:', response.data);







          setCurrentStep(currentStep + 1);







        } catch (error) {







          console.error('Error sending initial user details:', error.response?.data || error.message);







          // Show error message to user







          setErrors(prev => ({







            ...prev,







            submit: 'Failed to save your information. Please try again.'







          }));







        } finally {







          setIsFirstStepSubmitting(false); // Hide loader







        }







      } else {







        // For all other steps, just move to the next step







        setCurrentStep(currentStep + 1);







      }







    }







  };















  const handlePrevious = () => {







    if (currentStep > 0) {







      setCurrentStep(currentStep - 1);







    }







  };















  const calculateScore = () => {







    // Implement your score calculation logic here







    // This is a placeholder implementation







    return Math.floor(Math.random() * 100);







  };















  const renderResults = () => {







    return (







      <div className="results">







        <h2>Your PR Readiness Assessment Results</h2>







        <p>Your score: {score}</p>







        {error ? (







          <p className="error-message">{error}</p>







        ) : isGeneratingRecommendation ? (







          <p>Generating personalized recommendations...</p>







        ) : (







          <div className="ai-recommendations">







            <h3>AI-Generated Recommendations:</h3>







            <pre>{aiRecommendation}</pre>







          </div>







        )}







        <div className="cta-buttons">







          <button onClick={() => setShowCalendly(true)}>Book a Call Now</button>







          <button onClick={handleFinish}>Finish</button>







        </div>







        {showCalendly && <CalendlyWidget />}







      </div>







    );







  };















  const handleFinish = () => {







    // Add any final submission logic here if needed







    navigate('/thank-you');







  };















  console.log('Render - isSubmitted:', isSubmitted);















  return (







    <>







      <div className="pr-readiness-tool">







        <h2>PR Readiness Assessment Questions</h2>







        {isProcessing ? (







          <ProcessingVisual />







        ) : !isSubmitted ? (







          <>







            <div className="progress-bar">







              <div className="progress" style={{ width: `${(currentStep / (questions.length - 1)) * 100}%` }}></div>







            </div>







            {isFirstStepSubmitting ? (







              <FirstStepLoader />







            ) : (







              <form onSubmit={handleSubmit}>







                {renderQuestion(questions[currentStep])}







                {errors.submit && <div className="error-message">{errors.submit}</div>}







                <div className="navigation-buttons">







                  {currentStep > 0 && (







                    <button type="button" onClick={handlePrevious}>Previous</button>







                  )}







                  {currentStep < questions.length - 1 && (







                    <button type="button" onClick={handleNext} disabled={isFirstStepSubmitting}>







                      {currentStep === 0 && isFirstStepSubmitting ? 'Saving...' : 'Next'}







                    </button>







                  )}







                  {currentStep === questions.length - 1 && (







                    <button type="submit" disabled={isLoading}>







                      {isLoading ? 'Processing...' : 'Submit'}







                    </button>







                  )}







                </div>







              </form>







            )}







            {isLoading && (







              <div className="flex justify-center items-center">







                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>







              </div>







            )}







          </>







        ) : (







          renderResults()







        )}







      </div>







      <Analytics />







    </>







  );







};















export default PRReadinessTool;






